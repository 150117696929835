import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterWrapper = styled.footer`
  background-color: #333;
  color: white;
  text-align: center;
  padding: 15px;

  p {
    margin: 0;
  }

  a {
    color: white;
    margin: 0 10px;
    text-decoration: none;
  }
`;

const Footer = () => (
  <FooterWrapper>
    <p>Made with ❤️ using React</p>
    <p>
      <Link to="/privacy-policy">Privacy Policy</Link>
      <span>|</span>
      <Link to="/terms-of-use">Terms of Use</Link>
    </p>
  </FooterWrapper>
);

export default Footer;
