import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { FaEnvelope, FaLock, FaTimes, FaUser } from 'react-icons/fa';

import  LoadingSpinner from '../shared/components/UIElements/LoadingSpinner'
import { AuthContext } from '../shared/context/auth-context';



const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1000;
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  max-width: 400px;
  width: 100%;
  box-sizing: border-box;
`;

const TabsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
`;

const TabButton = styled.button`
  padding: 10px;
  font-size: 16px;
  background-color: ${(props) => (props.active ? '#4caf50' : 'transparent')};
  color: ${(props) => (props.active ? 'white' : 'black')};
  border: none;
  cursor: pointer;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const StyledInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const InputIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  color: #555;
`;

const StyledInput = styled.input`
  padding: 10px 30px 10px 40px;
  font-size: 16px;
  width: 100%;
`;

const StyledButton = styled.button`
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;


const AuthModal = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [activeTab, setActiveTab] = useState('login');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [fullNameError, setFullNameError] = useState('');
  const [authError, setAuthError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [isLoading, setIsLoding] = useState(false);
  const auth = useContext(AuthContext);


  // Function to reset all error states
  const resetErrors = () => {
    setEmailError('');
    setPasswordError('');
    setFullNameError('');
    setAuthError('');
    setSuccessMessage('');
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      setEmailError('Email cannot be empty');
      return false;
    }

    if (!emailRegex.test(email)) {
      setEmailError('Invalid email format');
      return false;
    }

    setEmailError('');
    return true;
  };

  const validatePassword = () => {
    if (!password) {
      setPasswordError('Password cannot be empty');
      return false;
    }
    if (!password || password.length < 6) {
      setPasswordError('Password must be at least 6 characters.');
      return false;
    }
    setPasswordError('');
    return true;
  };

  const validateFullName = () => {
    const forbiddenNames = ['author', 'admin', 'superuser', 'moderator'];
  
    if (!fullName) {
      setFullNameError('Full Name cannot be empty');
      return false;
    }
  
    if (forbiddenNames.includes(fullName.trim().toLowerCase())) {
      setFullNameError('Name cannot be one of the forbidden names');
      return false;
    }
  
    setFullNameError('');
    return true;
  };
  
  

  // Login
  const handleLogin = async () => {
    resetErrors();
  
    if (!validateEmail() || !validatePassword()) {
      return;
    }
  
    try {
      // Make a request to your login API endpoint
      setIsLoding(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });
  
      if (response.ok) {
        setIsLoding(false);
        const responseData = await response.json();
        auth.login(responseData.userId, responseData.name, responseData.email, responseData.token);
        setSuccessMessage('Login successful! Redirecting...');
        setTimeout(() => {
          onClose();
        }, 1000);
      } else {
        setIsLoding(false);
        const errorData = await response.json();
        setAuthError(errorData.message || 'Login failed. Please check your credentials and try again.');
      }
    } catch (error) {
      setIsLoding(false);
      setAuthError('Login failed. Please try again.');
    }
  };

  // signup
  const handleSignup = async () => {
    resetErrors(); // Reset errors before any action

    if (!validateEmail() || !validatePassword() || !validateFullName()) {
      return;
    }

    try {
      // Make a request to your signup API endpoint
      setIsLoding(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: fullName,
          email,
          password,
        }),
      });

      if (response.ok) {
        setIsLoding(false);
        const responseData = await response.json();
        auth.login(responseData.userId,responseData.name, responseData.email, responseData.token);
        setSuccessMessage('Signup successful! Redirecting...');
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        setIsLoding(false);
        const errorData = await response.json();
        setAuthError(errorData.message || 'Signup failed. Please try again.');
      }
    } catch (error) {
      setIsLoding(false);
      setAuthError('Signup failed. Please try again.');
    }
  };

  // Password recovery
  const handleForgotPassword = async () => {
    resetErrors();
    setShowForgotPassword(true);  
    try {
      if (!validateEmail()) {
        return;
      }
  
      setIsLoding(true);
  
      // Make a request to your forgot password API endpoint
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      });
  
      setIsLoding(false);
  
      if (response.ok) {
        const responseData = await response.json();
        setSuccessMessage(responseData.message);
        // You can handle success accordingly, e.g., show a message to the user
      } else {
        const errorData = await response.json();
        setAuthError(errorData.message || 'Forgot password failed. Please try again.');
      }
    } catch (error) {
      setIsLoding(false);
      setAuthError('Forgot password failed. Please try again.');
    }
  };

  const handleGoBack = () => {
    resetErrors(); // Reset errors before going back
    setShowForgotPassword(false);
  };

  const handleTabChange = (tab) => {
    resetErrors(); // Reset errors when changing tabs
    setActiveTab(tab);
  };

  // Reset errors when the modal is closed
  useEffect(() => {
    if (!onClose) return;

    return () => {
      resetErrors();
    };
  }, [onClose]);

  
  return (
    <>
      <ModalOverlay onClick={onClose} />
      <ModalWrapper>
        <CloseButton onClick={onClose}>
          <FaTimes />
        </CloseButton>
        {!showForgotPassword && (
          <TabsWrapper>
            <TabButton active={activeTab === 'login'} onClick={() => handleTabChange('login')}>
                Login
              </TabButton>
              <TabButton active={activeTab === 'signup'} onClick={() => handleTabChange('signup')}>
                Signup
            </TabButton>
          </TabsWrapper>
        )}
        <ModalContent>
        {isLoading && <LoadingSpinner asOverlay />}
        {showForgotPassword ? (
        <>
          <h2 style={{ color: 'rgb(51, 51, 51)' }}>Recover Password</h2>
          <StyledInputWrapper>
            <InputIcon>
              <FaEnvelope />
            </InputIcon>
            <StyledInput
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              onBlur={validateEmail}
            />
          </StyledInputWrapper>
          {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
          {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}
          {authError && <div style={{ color: 'red' }}>{authError}</div>}
          <StyledButton onClick={handleForgotPassword} style={{ color: 'rgb(51, 51, 51)' }}>
            Send Recovery Email
          </StyledButton>
          <p>
            <a href="/#" onClick={handleGoBack} style={{ color: 'rgb(51, 51, 51)' }}>
              Go Back
            </a>
          </p>
        </>
      ) : (
            <>
              <StyledInputWrapper>
                <InputIcon>
                  <FaEnvelope />
                </InputIcon>
                <StyledInput
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  onBlur={validateEmail}
                />
              </StyledInputWrapper>
              {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
              <StyledInputWrapper>
                <InputIcon>
                  <FaLock />
                </InputIcon>
                <StyledInput
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  onBlur={validatePassword}
                />
              </StyledInputWrapper>
              {passwordError && <div style={{ color: 'red' }}>{passwordError}</div>}
              {activeTab === 'signup' && (
                <>
                  <StyledInputWrapper>
                    <InputIcon>
                      <FaUser />
                    </InputIcon>
                    <StyledInput
                      type="text"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      placeholder="Full Name"
                      onBlur={validateFullName}
                    />
                  </StyledInputWrapper>
                  {fullNameError && <div style={{ color: 'red' }}>{fullNameError}</div>}
                </>
              )}
              {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}
              {authError && <div style={{ color: 'red' }}>{authError}</div>}
              <StyledButton onClick={activeTab === 'login' ? handleLogin : handleSignup}>
                {activeTab === 'login' ? 'Login' : 'Signup'}
              </StyledButton>
              {activeTab === 'login' && (
                <p>
                  <a href="/#" onClick={handleForgotPassword} style={{ color: 'rgb(51, 51, 51)' }}>
                    Forgot Password
                  </a>
                </p>
              )}
              <p style={{ fontSize: '12px', color: 'rgb(51, 51, 51)', marginTop: '10px' }}>
                  {activeTab === 'login' ? (
                    <>
                      Click “Login” to agree to SetuShubham.com's <a style={{ color: 'rgb(51, 51, 51)' }} href="/terms-of-use" target="_blank" rel="noopener noreferrer"> Terms of Use </a>
                      and acknowledge that SetuShubham.com's <a href="/privacy-policy" style={{ color: 'rgb(51, 51, 51)' }} target="_blank" rel="noopener noreferrer">Privacy Policy</a> applies to you.
                    </>
                  ) : (
                    <>
                      Click “Signup” to agree to SetuShubham.com's <a style={{ color: 'rgb(51, 51, 51)' }} href="/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use </a> 
                       and acknowledge that SetuShubham.com's <a href="/privacy-policy" target="_blank" style={{ color: 'rgb(51, 51, 51)' }} rel="noopener noreferrer">Privacy Policy</a> applies to you.
                    </>
                  )}
              </p>
            </>
          )}
        </ModalContent>
      </ModalWrapper>
    </>
  );
};

export default AuthModal;