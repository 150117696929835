import React from 'react';
import styled from 'styled-components';

const Media = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #222;
  top: 0;
  left: 0;
  z-index: -1;
`;

const LazyImage = ({ src, alt, type = 'image' }) => {
  return (
    <React.Suspense fallback={<div></div>}>
      <Media>
        {type === 'image' && <img src={src} alt={alt} />}
        {type === 'video' && (
          <video autoPlay loop muted playsInline>
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </Media>
    </React.Suspense>
  );
};

export default LazyImage;
