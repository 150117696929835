import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 40px auto;  
  padding: 4em 2em;
  font-family: Arial, sans-serif;
  line-height: 1.6;
`;

const Title = styled.h1`
  font-size: 2em;
  color: #333;
`;

const SectionTitle = styled.h2`
  font-size: 1.5em;
  color: #333;
`;

const Paragraph = styled.p`
  color: #555;
`;

const PrivacyPolicy = () => {
  return (
    <Wrapper>
      <Title>Your Privacy is Important to Me</Title>
      <p>This Privacy Policy outlines how setushubham.com collects, uses, maintains, and discloses information collected from users of the website.</p>
      
      <SectionTitle>Information I Collect:</SectionTitle>
      <Paragraph>
        I may collect personal identification information from users when they visit my site, subscribe to newsletters, or fill out a form. Users may be asked for their name, email address, and other relevant details.
      </Paragraph>

      <SectionTitle>How I Use Collected Information:</SectionTitle>
      <Paragraph>
        I may use the information collected for various purposes, including to improve customer service, personalize user experience, send periodic emails, and respond to inquiries or requests.
      </Paragraph>

      <SectionTitle>Protection of Information:</SectionTitle>
      <Paragraph>
        I adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information.
      </Paragraph>

      <SectionTitle>Third-Party Websites:</SectionTitle>
      <Paragraph>
        Users may find advertising or other content on our site that links to the sites and services of our partners, suppliers, advertisers.
      </Paragraph>
    </Wrapper>
  );
};

export default PrivacyPolicy;
