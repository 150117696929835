import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AuthContext } from '../shared/context/auth-context';


const Container = styled.div`
  max-width: 400px;
  margin: 14vh 1vh;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40vh;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 10px;
`;

const SuccessMessage = styled.div`
  color: green;
  margin-bottom: 10px;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;



const ResetPassword = () => {
  const auth = useContext(AuthContext);
const { search } = useLocation();  
  const queryParams = new URLSearchParams(search);
  const token = queryParams.get('token');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [authError, setAuthError] = useState('');

  const validatePassword = () => {
    if (!newPassword || newPassword.length < 6) {
      setPasswordError('Password must be at least 6 characters.');
      return false;
    }

    if (newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match.');
      return false;
    }

    setPasswordError('');
    return true;
  };

  const handleResetPassword = async () => {
    if (!validatePassword()) {
      return;
    }

    // Make a request to your reset password API endpoint
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: token,
          newPassword,
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        setSuccessMessage(responseData.message);
        // You can handle success accordingly, e.g., show a message to the user
      } else {
        const errorData = await response.json();
        setAuthError(errorData.message || 'Password reset failed. Please try again.');
      }
    } catch (error) {
      console.error('Reset password failed', error);
      setAuthError('Reset password failed. Please try again.');
    }
  };

  return (
    // Check if the user is already logged in
    auth.isLoggedIn ? (
      <Container>
        <Title>Already Logged In</Title>
        <p>It seems you are already logged in!</p>
        <p>Go to <a href="/">Home</a></p>
      </Container>
    ) : (
      <Container>
        <Title>Reset Password</Title>
        <Input
          type="password"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {passwordError && <ErrorMessage>{passwordError}</ErrorMessage>}
        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
        {authError && <ErrorMessage>{authError}</ErrorMessage>}
        <Button onClick={handleResetPassword}>Reset Password</Button>
        <p>Go to <a href="/">Home</a></p>
      </Container>
    )
  );
};

export default ResetPassword;
