import React from 'react';
import styled from 'styled-components';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

import myImage from '../assets/images/setu.jpeg';

const AboutMeWrapper = styled.div`
  text-align: center;
  margin: 60px auto;
  padding: 2rem;

  h2 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #666;
  }

  img {
    max-width: 100%;
    max-height: 400px;
    width: auto;
    height: auto;
    border-radius: 8px;
    margin: 20px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const Paragraph = styled.p`
  color: #555;
`;

const LocationIcon = styled.span`
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 5px;
  }
`;

const AboutMe = () => (
  <AboutMeWrapper>
    <h2>Know Me</h2>
    <Helmet>
      <title>Know Me | Setu Shubham</title>
      <meta name="description" content="Passionate software engineer based in Bengaluru, India. Exploring technology, travel, and tales." />
    </Helmet>
    <img src={myImage} alt="Setu Shubham" />
    <LocationIcon>
      <FaMapMarkerAlt />
      <span>Cologne, Germany</span>
    </LocationIcon>
    <Paragraph>
    🚀 Welcome to the digital realm of Setu Shubham, a passionate software engineer navigating the tech landscape from the vibrant city of Bangalore! 👨‍💻✨
    </Paragraph>

    <Paragraph>
     Embark on a journey with me as I dive into the intricacies of programming, constantly fueled by an insatiable curiosity for emerging technologies. Whether it's unraveling the mysteries of code or embracing the latest innovations, I thrive on the ever-evolving world of software development.
    </Paragraph>

    <Paragraph>
      Beyond the confines of lines and algorithms, I find joy in exploring the world and freezing moments in time through my lens. Traveling fuels my adventurous spirit, and photography allows me to encapsulate the beauty I encounter along the way. 🌍📸
    </Paragraph>

    <Paragraph>
    This digital space isn't just about code and pixels; it's a canvas where I paint my personal experiences and share the lessons life has graciously taught me. Join me on this rollercoaster of technology, travel, and tales – where every click, keystroke, and journey holds a story waiting to be shared.
    </Paragraph>
     
     <Paragraph>
     Fasten your seatbelt, and let's embark on this thrilling ride together! 🚀✨
     </Paragraph>
  </AboutMeWrapper>
);

export default AboutMe;
