import React, {useEffect} from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga4';

import Navbar from './components/Navbar';
import HeroBanner from './components/HeroBanner';
import AboutMe from './components/AboutMe';
import GlobalStyles from './styles/GlobalStyles';
import Travel from './components/Travel';
import BlogList from './components/BlogList';
import Tech from './components/Tech';
import StoryTelling from './components/StoryTelling';
import Footer from './components/footer';
import BlogDetails from './components/BlogDetails';
import './App.css'
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfUse from './components/termsOfUse';
import ConnectAndSupport from './components/ConnectSupport';
import ResetPassword from './components/RestPassword';
import NotFoundPage from './components/NotFoundPage';
import { AuthContext } from './shared/context/auth-context';
import { useAuth } from './shared/hooks/auth-hook';


const AppWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
`;


function App() {
  const { token, login, logout, userId, email, userName } = useAuth();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        email: email,
        userName: userName,
        login: login,
        logout: logout
      }}
    >
    <Router>
      <AppWrapper>
        <GlobalStyles />
        <Navbar/>
        <ContentWrapper>
          <Routes>
            <Route path="/know-me" element={<AboutMe />} />
            <Route path="/tech" element={<Tech />} />
            <Route path="/storytelling" element={<StoryTelling />} />
            <Route path="/travel" element={<Travel />} />
            <Route path="blogDetails/:id" element={< BlogDetails />} />
            <Route path="privacy-policy" element={< PrivacyPolicy />} />
            <Route path="terms-of-use" element={< TermsOfUse />} />
            <Route path="connect-and-support" element={< ConnectAndSupport />} />
            <Route path="/reset-password" element={< ResetPassword/>} />
            <Route
              path="/"
              element={
                <>
                  <HeroBanner />
                  <BlogList />
                </>
              }
            />
             <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </ContentWrapper>
        <Footer />
      </AppWrapper>
    </Router>
     </AuthContext.Provider>
  );
}

export default App;
