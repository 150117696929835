import React, { useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import AuthModal from './AuthModal';
import { AuthContext } from '../shared/context/auth-context';

const NavbarWrapper = styled.nav`
  background-color: #333;
  padding: 15px;
  color: white;
  text-align: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
    ${props =>
      props.isMenuOpen &&
      css`
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 60px; 
        left: 0;
        background-color: #333;
      `}
  }
`;

const MenuItem = styled(NavLink)`
  text-decoration: none;
  color: white;
  margin: 0 15px;
  cursor: pointer;
  font-weight: bold;

  &.active {
    font-weight: bold;
  }

  @media (max-width: 768px) {
    margin: 10px 0;
  }
`;

const MenuIcon = styled.div`
  cursor: pointer;
  font-size: 2em;
  margin-right: 15px;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;
const AuthButtons = styled.div`
display: flex;
align-items: center;
margin-left: auto;
margin-right: 2rem;

@media (max-width: 768px) {
  margin-left: 0;
}
`;

const LogoutButton = styled.button`
background-color: #4caf50;
border: none;
color: white;
padding: 10px 15px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 16px;
margin-left: 15px;
border-radius: 20px;
cursor: pointer;
`;


const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const auth = useContext(AuthContext);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <NavbarWrapper>
      <MenuIcon onClick={toggleMenu}>&#9776;</MenuIcon>
      <MenuContainer isMenuOpen={isMenuOpen}>
      <MenuItem to="/" exact activeClassName="active">
          Home
        </MenuItem>
        <MenuItem to="/know-me" activeClassName="active">
          Know Me
        </MenuItem>
        <MenuItem to="/travel" activeClassName="active">
          Travel
        </MenuItem>
        <MenuItem to="/tech" activeClassName="active">
          Tech
        </MenuItem>
        <MenuItem to="/storytelling" activeClassName="active">
          Story Telling
        </MenuItem>
        <MenuItem to="/connect-and-support" activeClassName="active">
          Connect and Support
        </MenuItem>
      </MenuContainer>
      <AuthButtons>
        {auth.isLoggedIn ? (
          <>
            <p>Welcome, {auth.userName}</p>
            <LogoutButton onClick={auth.logout}>Logout</LogoutButton>
          </>
        ) : (
          <LogoutButton onClick={handleModalOpen}>Sign In / Sign Up</LogoutButton>
        )}
      </AuthButtons>
      {isModalOpen && (
        <AuthModal onClose={handleModalClose} />
      )}
    </NavbarWrapper>
  );
};

export default Navbar;