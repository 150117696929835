import React, { useEffect, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { createClient } from 'contentful';
import { Link } from 'react-router-dom';

const Layout = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 75%;
  padding: 2em 1em 0;
`;

const Posts = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;


const Post = styled.section`
  width: calc(50% - 1em);
  box-sizing: border-box;
  margin-bottom: 2em;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PostHeader = styled.header`
.post-logo {
  border-radius: 0px;
  max-width: 100%;
  height: auto;
  margin-bottom: 1.6em;
  max-height: 300px;
}
`;

const PostTitle = styled.h2`
  font-size: 2em;
  color: #222;
  margin-bottom: 0.2em;
`;

const PostMeta = styled.p`
  color: #999;
  font-size: 90%;
  margin: 0;
`;

const PostCategory = styled.span`
  margin: 0 0.1em;
  padding: 0.3em 1em;
  color: #fff;
  font-size: 80%;
  background: #999;

  &.post-category-js {
    background: #F0DB4F;
  }
`;

const Button = styled(Link)`
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s;

  &:hover {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  }
`;

const BlogList = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  // Memoize the client instance to avoid unnecessary changes
  const client = useMemo(
    () =>
      createClient({
        space: process.env.REACT_APP_SPACE,
        accessToken: process.env.REACT_APP_ACCESS
      }),
    [] // Empty dependency array to create the client only once
  );

  const getAllEntries = useCallback(async () => {
    try {
      await client.getEntries().then((entries) => {
        if (entries) {
          setBlogPosts(entries);
        }
      });
    } catch (error) {
      console.error(`Error fetching authors ${error}`);
    }
  }, [client]);

  useEffect(() => {
    getAllEntries();
  }, [getAllEntries, client]);



  return (
    <Layout id="layout" className="pure-g">
      <Content className="content pure-u-1 pure-u-md-3-4">
        <div>
        {blogPosts.length === 0 ? (
           <p>No data</p>
          ) : (
          <Posts className="posts">
            {blogPosts?.items?.map((post) => (
              <Post className="post" key={post?.sys?.id}>
                <PostHeader className="post-header">
                  <img
                    src={post?.fields?.blogImage?.fields?.file?.url}
                    title=""
                    alt={post?.fields?.blogTitle}
                    className="post-logo"
                  />
                  <PostTitle className="post-title pt-3">{post?.fields?.blogTitle}</PostTitle>
                  <PostMeta className="post-meta">
                    <span></span>
                    <small>
                    {post?.fields?.createdDate ? (
                          <span>
                            {new Intl.DateTimeFormat('en-GB', {
                              month: 'long',
                              day: '2-digit',
                              year: 'numeric',
                            }).format(new Date(post.fields.createdDate))}
                          </span>
                        ) : (
                          ""
                        )}
                    </small>
                  </PostMeta>
                </PostHeader>
                <PostCategory>
                    <span>{post?.fields?.categories}</span>
                </PostCategory>
                <p>{post?.fields?.blogSummary}</p>
                <Button
                  to={`/blogDetails/${post?.sys?.id}`}
                  className="button button1"
                >
                  Read More
                </Button>
              </Post>
            ))}
          </Posts>
           )}
        </div>
      </Content>
    </Layout>
  );
};

export default BlogList;
