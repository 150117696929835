import React, { useState, useEffect, useMemo } from 'react';
import { createClient } from 'contentful';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import LazyImage from '../shared/LazyLoading/LazyImage';
import cover from '../assets/images/my-cover.mp4';

const HeroBannerWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 0;
`;

const ContentContainer = styled.div`
  position: relative;
  z-index: 1;
  color: white;
  text-align: center;
  padding: 10rem 0;
`;

const PenTypography = styled.div`
  font-family: 'Dancing Script', cursive;
  font-size: 80px;
  transition: transform 0.5s ease-in-out;
`;

const AnimatedPenTypography = styled(PenTypography)`
  transform: translateY(${props => (props.visible ? '0' : '60px')});
`;

const Subtitle = styled.p`
  opacity: ${props => (props.visible ? '1' : '0')};
  transition: opacity 0.5s ease-in-out;
`;

const SearchBar = styled.input`
  width: 300px;
  padding: 10px;
  border: none;
  border-radius: 20px;
  outline: none;
  margin-top: 20px;

  @media (max-width: 768px) {
    width: 80%;
    margin: 2px auto;
  }
`;

const ResultsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #333;
  border: 1px solid #555;
  border-radius: 10px;
  position: absolute;
  width: 300px;
  left: 50%;
  transform: translateX(-50%);
  max-height: 150px;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 80%;
    left: 10%;
    transform: none;
  }
`;

const ResultItem = styled.li`
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #555;

  &:last-child {
    border-bottom: none;
  }
`;

const HeroBanner = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isPenVisible, setIsPenVisible] = useState(false);
  const [videoPlayed, setVideoPlayed] = useState(false);
  const navigate = useNavigate();

  // Memoize the client instance
  const client = useMemo(
    () =>
      createClient({
        space: process.env.REACT_APP_SPACE,
        accessToken: process.env.REACT_APP_ACCESS
      }),
    []
  );

  useEffect(() => {
    const fetchBlogTitles = async () => {
      try {
        // Check if searchQuery is empty, and if not, fetch blog titles
        if (searchQuery.trim() !== '') {
          const response = await client.getEntries({
            content_type: 'blog',
            query: searchQuery
          });
          // Update the search results state
          setSearchResults(response.items);
        } else {
          setSearchResults([]);
        }
      } catch (error) {
        console.error('Error fetching blog titles:', error);
      }
    };

    // Fetch blog titles when the search query changes
    fetchBlogTitles();
  }, [client, searchQuery]);

  // Redirect to the blog details page when a title is clicked
  const handleBlogClick = blogId => {
    navigate(`/blogDetails/${blogId}`);
  };

  // Function to handle touch event and play the video
  const handleTouchStart = () => {
    // Play the video only if it hasn't been played before
    if (!videoPlayed) {
      const video = document.getElementById('hero-video');
      if (video) {
        video.play();
        setVideoPlayed(true);
      }
    }
  };

  useEffect(() => {
    const showPenTimeout = setTimeout(() => {
      setIsPenVisible(true);
    }, 500);

    return () => {
      clearTimeout(showPenTimeout);
    };
  }, []);

  return (
    <>
    <Helmet>
    <title> Home | Setu Shubham</title>
    <meta name="description" content="Exploring code, journeying to new places, and sharing compelling stories." />
     </Helmet>
    <HeroBannerWrapper onTouchStart={handleTouchStart}>
      <LazyImage src={cover} alt="my cover" type="video" />
      <Overlay />
      <ContentContainer>
        <AnimatedPenTypography visible={isPenVisible}>Setu Shubham</AnimatedPenTypography>
        <Subtitle visible={isPenVisible}>
          Exploring code, journeying to new places, and sharing compelling stories.
        </Subtitle>
        <SearchBar
          type="text"
          placeholder="Search for blogs"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
        {searchResults.length > 0 && (
          <ResultsList>
            {searchResults.map(blog => (
              <ResultItem key={blog.sys.id} onClick={() => handleBlogClick(blog.sys.id)}>
                {blog.fields.blogTitle}
              </ResultItem>
            ))}
          </ResultsList>
        )}
      </ContentContainer>
    </HeroBannerWrapper>
    </>
  );
};

export default HeroBanner;
