import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../shared/context/auth-context';
import { FaTrash } from 'react-icons/fa';

// Function to fetch comments from the API
const fetchComments = async (blogId) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/comments/${blogId}`);
  const data = await response.json();
  return data;
};

// Function to add a new comment
const addComment = async (commentData, token) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/comments`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentData),
  });
  const data = await response.json();
  return data;
};

const CommentHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 20px 0;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  padding: 10px 0;
  font-size: 1em;
`;

const CommentsWrapper = styled.div`
  margin-top: 20px;
`;

const CommentContainer = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 2rem;
`;

const Avatar = styled.div`
  width: 40px;
  height: 40px;
  background-color: #4caf50;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: white;
`;

const AvatarText = styled.span`
  text-transform: uppercase;
`;

const CommentContent = styled.div`
  flex: 1;
  text-align: left;
`;

const CommentText = styled.p`
  margin: 8px 0;
  text-align: left;
  font-family: Georgia, 'Cambria', serif;
  color: #444;
  font-size: 0.8em;
`;

const Username = styled.strong`
  margin-right: 8px;
  font-size: 16px;
`;

const Timestamp = styled.p`
  margin: 4px 0;
  font-size: 10px;
`;

const CommentForm = styled.form`
  margin-top: 20px;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
`;

const CommentTextarea = styled.textarea`
  width: 100%;
  resize: none;
  margin-right: 10px;
`;

const SubmitButton = styled.button`
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
`;

const DeleteIcon = styled(FaTrash)`
  cursor: pointer;
  color: #333;
  margin-left: 5px;
  position: absolute;
  top: 5px;
  right: 5px;
`;

const ReplyContainer = styled.div`
  margin-left: 50px;
  padding-left: 10px;
  border-left: 1px solid #ddd;
`;

const ReplyText = styled.p`
  margin: 8px 0;
  text-align: left;
  font-family: Georgia, 'Cambria', serif;
  color: #666;
  font-size: 0.7em;
`;

const ReplyForm = styled.form`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
`;

const formatDate = (timestamp) => {
  if (!timestamp || isNaN(new Date(timestamp).getTime())) {
    return "Timestamp not available";
  }

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  return new Intl.DateTimeFormat('en-US', options).format(new Date(timestamp));
};



const CommentSection = ({ blogId }) => {
  const auth = useContext(AuthContext);
  const { userId, userName, isLoggedIn, token } = auth;
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [newReply, setNewReply] = useState({});
  const [showError, setError] = useState('');

  useEffect(() => {
    // Fetch comments when the component mounts
    fetchComments(blogId).then((data) => setComments(data.comments));
  }, [blogId]);

  const handleCommentSubmit = async (e) => {
    e.preventDefault();

    if (!userId) {
      return;
    }

    if (!newComment.trim()) {
      // Do not allow submitting empty comments
      return;
    }

    // Add the new comment
    const commentData = {
      userId: userId,
      name: userName,
      blogId,
      commentBody: newComment,
    };

    try {
      const newCommentData = await addComment(commentData, token);
      setComments((prevComments) => {
        // Check if prevComments is defined and an array
        if (Array.isArray(prevComments) && prevComments.length === 0) {
          return [newCommentData.comment];
        } else {
          return [...(prevComments || []), newCommentData.comment];
        }
      });
      setNewComment('');
    } catch (error) {
      console.error('Error adding comment:', error);
      setError('Something went wrong! Please try again');
    }
  };

  const handleCommentDelete = async (commentId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/comments/${commentId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        // Remove the deleted comment from the state
        const updatedComments = comments.filter((comment) => comment.id !== commentId);
        setComments(updatedComments);
      } else {
        console.error('Error deleting comment:', response.statusText);
        setError('Something went wrong! Please try again');
      }
    } catch (error) {
      console.error('Error deleting comment:', error);
      setError('Something went wrong! Please try again');
    }
  };

  const handleReplySubmit = async (e, commentId) => {
    e.preventDefault();
  
    if (!userId) {
      return;
    }
  
    const replyBody = newReply[commentId];
  
    if (!replyBody.trim()) {
      // Do not allow submitting empty replies
      setError('Comment cannot be empty');
      return;
    }
  
    // Add the new reply
    const replyData = {
      userId: userId,
      name: userName,
      commentId,
      replyBody,
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/comments/reply`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(replyData),
      });
  
      if (response.ok) {
        // Fetch comments again to get the updated data with reply ID
        const updatedCommentsData = await fetchComments(blogId);
        setComments(updatedCommentsData.comments);
  
        // Clear the reply input
        setNewReply({ ...newReply, [commentId]: '' });
      } else {
        console.error('Error adding reply:', response.statusText);
        setError('Something went wrong! Please try again');
      }
    } catch (error) {
      console.error('Error adding reply:', error);
      setError('Something went wrong! Please try again');
    }
  };
  
  
  

  const handleReplyDelete = async (commentId, replyId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/comments/${commentId}/reply/${replyId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.ok) {
        // Remove the deleted reply from the state
        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment.id === commentId
              ? {
                  ...comment,
                  replies: (comment.replies || []).filter((reply) => reply._id !== replyId),
                }
              : comment
          )
        );
      } else {
        console.error('Error deleting reply:', response.statusText);
        setError('Something went wrong! Please try again');
      }
    } catch (error) {
      console.error('Error deleting reply:', error);
      setError('Something went wrong! Please try again');
    }
  };
  
  

  return (
    <CommentsWrapper>
      {!isLoggedIn && (
        <CommentHeading>Please log in to add or reply to comments.</CommentHeading>
      )}
      {isLoggedIn && (
        <CommentForm onSubmit={handleCommentSubmit}>
          <CommentTextarea
            placeholder="Add a comment..."
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
          />
          <SubmitButton type="submit">Add</SubmitButton>
        </CommentForm>
      )}

      {comments && comments.length > 0 ? (
        comments.map((comment) => (
          <CommentContainer key={comment.id}>
            {isLoggedIn && userId === comment.user.userId && (
              <DeleteIcon onClick={() => handleCommentDelete(comment.id)} />
            )}
            <Avatar>
              <AvatarText>{comment.user.name.charAt(0)}</AvatarText>
            </Avatar>
            <CommentContent>
              <Username>{comment.user.name}</Username>
              <Timestamp>{formatDate(comment.timestamp)}</Timestamp>
              <CommentText>{comment.commentBody}</CommentText>
              {comment.replies && comment.replies.length > 0 && (
                <ReplyContainer>
                  {comment.replies.map((reply) => (
                    <CommentContainer key={reply._id}>
                      {isLoggedIn && userId === reply.user.userId && (
                        <DeleteIcon onClick={() => handleReplyDelete(comment.id, reply._id)} />
                      )}
                      <Avatar>
                        <AvatarText>{reply.user.name.charAt(0)}</AvatarText>
                      </Avatar>
                      <CommentContent>
                        <Username>{reply.user.name}</Username>
                        <Timestamp>{formatDate(reply.timestamp) || "Timestamp not available"}</Timestamp>
                        <ReplyText>{reply.replyBody}</ReplyText>
                      </CommentContent>
                    </CommentContainer>
                  ))}
                </ReplyContainer>
              )}
              {isLoggedIn && (
                <ReplyForm onSubmit={(e) => handleReplySubmit(e, comment.id)}>
                  <CommentTextarea
                    placeholder="Reply to this comment..."
                    value={newReply[comment.id] || ''}
                    onChange={(e) => setNewReply({ ...newReply, [comment.id]: e.target.value })}
                  />
                  <SubmitButton type="submit">Reply</SubmitButton>
                </ReplyForm>
              )}
            </CommentContent>

            {showError && <ErrorMessage>{showError}</ErrorMessage>}
          </CommentContainer>
        ))
      ) : (
        <p>No comments available.</p>
      )}
    </CommentsWrapper>
  );
};

export default CommentSection;
