import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { createClient } from 'contentful';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { FacebookShareButton, 
          FacebookIcon, 
          TwitterShareButton,
          XIcon, 
          WhatsappShareButton, 
          WhatsappIcon, 
          LinkedinShareButton, 
          LinkedinIcon} from 'react-share';
import { FaPlay, FaStop, FaBan } from 'react-icons/fa';
import Prism from 'prismjs';
import 'prismjs/themes/prism.css';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-jsx';
import '../styles/Prism.css'
import { Helmet } from 'react-helmet';


import CommentSection from './Comments';


const Layout = styled.div`
  display: flex;
  justify-content: center;
  margin: 60px auto;
`;

const Content = styled.div`
  width: 75%;
  padding: 4em 1em 0;
`;

const BlogDetailsWrapper = styled.div`
  text-align: center;
`;

const BlogImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  max-height: 400px;
`;

const BlogTitle = styled.h2`
  font-size: 2em;
  color: #222;
  margin-bottom: 20px;
`;

const BlogMeta = styled.p`
  color: #999;
  font-size: 90%;
  margin-bottom: 20px;

  span {
    margin: 0 0.1em;
    padding: 0.3em 1em;
    color: #fff;
    font-size: 80%;
    background: #999;
  }
`;

const Bold = styled.strong`
  font-weight: bold;
`;

const Italic = styled.i`
  font-style: italic;
`;

const BlogContent = styled.div`
  font-family: Georgia, 'Cambria', serif;
  color: #444;
  line-height: 1.8em;
  margin-bottom: 20px;

  > p {
    margin-bottom: 1em;
  }

  > ${Bold} {
    font-weight: bold;
  }

  > ${Italic} {
    font-style: italic;
  }

  > blockquote {
    /* Add blockquote styles here */
  }

  .code-block {
    background-color: #1e1e1e;
    color: #abb2bf;
    border-radius: 8px;
    padding: 16px;
    margin: 16px 0;
    overflow: auto;
    position: relative;

    pre {
      margin: 0;
    }
  }

  .bash {
    background-color: #1e1e1e;
    color: #abb2bf;
    border-radius: 8px;
    padding: 16px;
    margin: 16px 0;
    overflow: auto;
    position: relative;

    pre {
      margin: 0;
    }
  }
`;

const SocialSharing = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  padding: 10px 0;
`;

const ShareButton = styled.div`
  margin-right: 10px;
`;

const PlayButton = styled.button`
  padding: 10px 15px;
  cursor: pointer;
  margin-right: auto;
  background-color: #4caf50;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 20px;
`;

const CouldNotPlay = styled.div`
    margin-right: auto;
    color:red;
`;

const PlayIcon = styled.span`
  vertical-align: middle; 
`;

const processContent = (content) => {
  const replacements = [
    { pattern: /\*\*\s*([^*]+)\s*\*\*/g, replacement: '<strong>$1</strong>' }, // Double asterisks for bold style
    { pattern: /\*\s*([^*]+)\s*\*/g, replacement: '<i>$1</i>' }, // Single asterisks for italic style
    { pattern: /__\s*([^__]+)\s*__/g, replacement: '<strong>$1</strong>' }, // Double underscores for bold style
    { pattern: /!\[([^\]]+)\]\(([^)]+)\)/g, replacement: '<img style="max-width: 100%;height: auto;border-radius: 8px;box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);margin-bottom: 20px;max-height: 300px;" alt="$1" src="$2" />' }, // Image
    
    {
      pattern: /-codeBlock\s+(\w+)\s+([\s\S]+?)\s+-codeBlock/g,
      replacement: (_, lang, code) => {
        // Check if the language identifier is supported
        if (Prism.languages[lang]) {
          return `
            <div class="code-block">
            <pre>
              ${Prism.highlight(code, Prism.languages[lang], lang)}
              </pre>
            </div>`;
        } else {
          // Return an error message if the language is not supported
          return `<div class="code-block" style="position: relative;">Unsupported language: ${lang}</div>`;
        }
      }
    },
    { 
      pattern: /-bash\s+([^]+?)\s+-bash/g, 
      replacement: (_, code) => `
        <div class="bash">
        <pre>
          ${Prism.highlight(code, Prism.languages.bash, 'bash')}
          </pre>
        </div>` 
    } // -bash content
  ];

  let processedContent = content;

  replacements.forEach(({ pattern, replacement }) => {
    processedContent = processedContent.replace(pattern, replacement);
  });

  // Replace newline characters with <br> tags
  processedContent = processedContent.replace(/\n/g, '<br>');

  return processedContent;
};


const BlogDetails = () => {
  const [blogPost, setBlogPost] = useState({});
  const { id } = useParams();
  const [shareUrl, setShareUrl] = useState('');
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isSpeakingError, setIsSpeakingError] = useState(false);
  const [sanitizedContent, setSanitizedContent] = useState('');

  // Memoize the client instance
  const client = useMemo(
    () =>
      createClient({
        space: process.env.REACT_APP_SPACE,
        accessToken: process.env.REACT_APP_ACCESS
      }),
    []
  );

  useEffect(() => {
    const getEntryById = async () => {
      try {
        const entry = await client.getEntry(id);
        if (entry) {
          setBlogPost(entry.fields);
          setShareUrl(window.location.href);
        }
      } catch (error) {
        console.error(`Error fetching blog post ${error}`);
      }
    };
    getEntryById();
  }, [id, client]);

  useEffect(() => {
    if (blogPost.postContent) {
      const processedContent = processContent(blogPost.postContent);
      const sanitizedContent = DOMPurify.sanitize(processedContent);
      setSanitizedContent(sanitizedContent);
    }
  }, [blogPost.postContent]);

  const handlePlayClick = () => {
    if ('speechSynthesis' in window) {
      setIsSpeakingError(false);
      const synthesis = window.speechSynthesis;
      const utterance = new SpeechSynthesisUtterance();

      const plainTextContent = blogPost.postContent.replace(/!\[[^\]]*\]\([^)]*\)/g, '');

      utterance.text = plainTextContent;

      if (!isSpeaking) {
        synthesis.speak(utterance);

        utterance.onend = () => {
          setIsSpeaking(false);
        };
      } else {
        synthesis.cancel();
        setIsSpeaking(false);
      }

      setIsSpeaking(!isSpeaking);
    } else {
      setIsSpeakingError(true);
    }
  };

  return (
    <Layout>
      <Content>
        {blogPost && (
          <BlogDetailsWrapper>
             <Helmet>
             <title>{blogPost.blogTitle}</title>
              <meta name="description" content={blogPost.blogSummary} />
              {/* Open Graph tags for social media sharing */}
              <meta property="og:title" content={blogPost.blogTitle} />
              <meta property="og:description" content={blogPost.blogSummary} />
              <meta property="og:image" content={blogPost.blogImage?.fields?.file?.url} />
            </Helmet>
            <BlogImage
              src={blogPost.blogImage?.fields?.file?.url}
              alt={blogPost.blogTitle}
            />

            <BlogTitle>{blogPost?.blogTitle}</BlogTitle>

            {/* Social Sharing Buttons */}
            <SocialSharing>
              {isSpeakingError ? (
                <CouldNotPlay>
                  <PlayIcon><FaBan /></PlayIcon>
                  Not supported
                </CouldNotPlay>
              ) : (
                <PlayButton onClick={handlePlayClick}>
                  <PlayIcon>
                    {isSpeaking ? <FaStop /> : <FaPlay />}
                  </PlayIcon>
                  {isSpeaking ? ' Stop' : ' Listen'}
                </PlayButton>
              )}

              {/* Facebook Share Button */}
              <ShareButton>
                <FacebookShareButton url={shareUrl} title={blogPost.blogTitle} imageUrl={blogPost.blogImage?.fields?.file?.url}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
              </ShareButton>

              {/* Twitter Share Button */}
              <ShareButton>
                <TwitterShareButton url={shareUrl} title={blogPost.blogTitle} imageUrl={blogPost.blogImage?.fields?.file?.url}>
                  <XIcon size={32} round />
                </TwitterShareButton>
              </ShareButton>

              {/* Whatsapp Share Button */}
              <ShareButton>
                <WhatsappShareButton url={shareUrl} imageUrl={blogPost.blogImage?.fields?.file?.url} title={blogPost.blogTitle} separator=":: ">
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </ShareButton>

              {/* Linkedin Share Button */}
              <ShareButton>
                <LinkedinShareButton url={shareUrl} imageUrl={blogPost.blogImage?.fields?.file?.url} title={blogPost.blogTitle}>
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </ShareButton>
            </SocialSharing>

            <BlogMeta>
              {blogPost?.categories && <span>{blogPost?.categories}</span>}
              <br />
              <small>
                {blogPost.createdDate ? (
                  new Intl.DateTimeFormat('en-GB', {
                    month: 'long',
                    day: '2-digit',
                    year: 'numeric',
                  }).format(new Date(blogPost.createdDate))
                ) : (
                  ""
                )}
              </small>
            </BlogMeta>

            <BlogContent dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
            < CommentSection blogId={id} />
          </BlogDetailsWrapper>
        )}
      </Content>
    </Layout>
  );
};

export default BlogDetails;