import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 4em 2em;
  margin: 40px auto;  
  font-family: Arial, sans-serif;
  line-height: 1.6;
`;

const Title = styled.h1`
  font-size: 2em;
  color: #333;
`;

const SectionTitle = styled.h2`
  font-size: 1.5em;
  color: #333;
`;

const Paragraph = styled.p`
  color: #555;
`;

const TermsOfUse = () => {
  return (
    <Wrapper>
      <Title>Terms and Conditions</Title>
      <p>Welcome to setushubham.com! By accessing this website, you agree to comply with and be bound by the following terms and conditions of use. If you disagree with any part of these terms and conditions, please do not use my website.</p>
      
      <SectionTitle>Content Disclaimer:</SectionTitle>
      <Paragraph>
        The content on this website is for general information purposes only and is subject to change without notice.
     </Paragraph>
    <Paragraph>
        I make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability concerning the website or the information, products, services, or related graphics contained on the website for any purpose.
    </Paragraph>

      <SectionTitle>User Conduct:</SectionTitle>
      <Paragraph>
       You agree to use this website for lawful purposes only and in a manner that does not infringe on the rights of, or restrict or inhibit the use and enjoyment of, this site by any third party.
      </Paragraph>

      <SectionTitle>Intellectual Property:</SectionTitle>
      <Paragraph>
      All content on this website, including text, graphics, logos, and images, is the property of setushubham.com and is protected by intellectual property laws.
      </Paragraph>

      <SectionTitle>Links to Other Websites:</SectionTitle>
      <Paragraph>
      My website may contain links to third-party websites. These links are provided for your convenience to provide further information. I have no responsibility for the content of linked websites.
      </Paragraph>

      <SectionTitle>Changes to Terms:</SectionTitle>
      <Paragraph>
       I may revise these terms and conditions at any time without notice. By using this website, you agree to be bound by the current version of these terms and conditions.
      </Paragraph>
    </Wrapper>
  );
};

export default TermsOfUse;
