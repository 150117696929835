import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { createClient } from 'contentful';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const Layout = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px auto;
`;

const Posts = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;


const Post = styled.section`
  width: calc(50% - 1em);
  box-sizing: border-box;
  margin-bottom: 2em;

  @media (max-width: 768px) {
    width: 100%; 
  }
`;

const PostHeader = styled.header`
.post-logo {
  border-radius: 0px;
  max-width: 100%;
  height: auto;
  margin-bottom: 1.6em;
  max-height: 300px;
}
`;

const PostTitle = styled.h2`
  font-size: 2em;
  color: #222;
  margin-bottom: 0.2em;
`;

const PostMeta = styled.p`
  color: #999;
  font-size: 90%;
  margin: 0;
`;

const Button = styled(Link)`
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s;

  &:hover {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  }
`;
const TechContent = styled.div`
  margin-top: 4rem;
  padding: 2rem;
}
`;
const StoryTelling = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  // Memoize the client instance
  const client = useMemo(
    () =>
      createClient({
        space: process.env.REACT_APP_SPACE,
        accessToken: process.env.REACT_APP_ACCESS
      }),
    []
  );

  useEffect(() => {
    const getEntriesByStoryTelling = async () => {
      try {
        const entries = await client.getEntries({
          content_type: 'blog', 
          'fields.categories': 'Story Telling'
        });
        setBlogPosts(entries);
      } catch (error) {
        console.error(`Error fetching entries: ${error.message}`);
      }
    };
  
    getEntriesByStoryTelling();
  }, [client]); // Include 'client' as a dependency


  return (
    <>
    <Helmet>
    <title>Story Telling | Setu Shubham</title>
    <meta name="description" content="Embark on a journey through the lens of Setu Shubham, a passionate storyteller and software engineer based in Bengaluru, India. Discover captivating tales, vibrant experiences, and the fusion of technology, travel, and life's adventures. Join me on this digital odyssey where every click unveils a new chapter of inspiration, innovation, and the art of storytelling. 🚀✨" />
    </Helmet>
    <Layout id="layout" className="pure-g">
      <TechContent className="content pure-u-1 pure-u-md-3-4">
        <div>
          <Posts className="posts">
            {blogPosts?.items?.map((post) => (
              <Post className="post" key={post.sys.id}>
                <PostHeader className="post-header">
                  <img
                    src={post.fields.blogImage.fields.file.url}
                    title=""
                    alt={post.fields.blogTitle}
                    className="post-logo"
                  />
                  <PostTitle className="post-title pt-3">{post.fields.blogTitle}</PostTitle>
                  <PostMeta className="post-meta">
                    <span></span>
                    <small>
                      {new Intl.DateTimeFormat('en-GB', {
                        month: 'long',
                        day: '2-digit',
                        year: 'numeric',
                      }).format(new Date(post.fields.createdDate))}
                    </small>
                  </PostMeta>
                </PostHeader>
                <p>{post.fields.blogSummary}</p>
                <Button
                  to={`/blogDetails/${post.sys.id}`}
                  className="button button1"
                >
                  Read More
                </Button>
              </Post>
            ))}
          </Posts>
        </div>
      </TechContent>
    </Layout>
    </>
  );
};

export default StoryTelling;
