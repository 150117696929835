import React from 'react';
import styled from 'styled-components';
import { useSpring, animated, config } from '@react-spring/web';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const NotFoundWrapper = styled.div`
  display: flex;
  margin: 20px 0;
  justify-content: center;
  align-items: center;
  height: 60vh;
`;

const AnimatedNotFoundContent = animated(styled.div`
  text-align: center;
`);

const NotFoundTitle = styled.h1`
  font-size: 3em;
  color: #333;
  margin-bottom: 20px;
`;

const NotFoundMessage = styled.p`
  font-size: 1.2em;
  color: #666;
`;

const AnimatedNotFoundTitle = animated(NotFoundTitle);
const AnimatedNotFoundMessage = animated(NotFoundMessage);

const NotFoundIcon = styled(FaExclamationTriangle)`
  font-size: 2em;
  margin-bottom: 10px;
  color: #FFA500;
`;

const HomeLink = styled(Link)`
  text-decoration: none;
  color: #4CAF50;
  font-size: 1.2em;
  margin-top: 20px;
  display: inline-block;
  transition: color 0.3s ease;

  &:hover {
    color: #45a049;
  }
`;

const NotFoundPage = () => {
  const fadeIn = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: config.molasses,
  });

  const flyIn = useSpring({
    to: { transform: 'translateY(-100px)' },
    from: { transform: 'translateY(0)' },
    loop: { reverse: true },
    config: config.wobbly,
  });

  return (
    <NotFoundWrapper>
      <AnimatedNotFoundContent style={fadeIn}>
        <NotFoundIcon />
        <AnimatedNotFoundTitle>404 Not Found</AnimatedNotFoundTitle>
        <AnimatedNotFoundMessage style={flyIn}>
          Sorry, the page you are looking for does not exist.
        </AnimatedNotFoundMessage>
        <HomeLink to="/">Go to Home</HomeLink>
      </AnimatedNotFoundContent>
    </NotFoundWrapper>
  );
};

export default NotFoundPage;
