import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { AuthContext } from '../shared/context/auth-context';
import  LoadingSpinner from '../shared/components/UIElements/LoadingSpinner'


const ConnectAndSupportWrapper = styled.div`
  padding: 20px;
  max-width: 400px;
  margin: 60px auto;
`;

const GreetingMessage = styled.p`
  font-size: 1.2em;
  margin-bottom: 20px;
`;

const SupportForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormInput = styled.input`
  margin-bottom: 10px;
  padding: 8px;
  font-size: 1em;
`;

const FormTextarea = styled.textarea`
  margin-bottom: 20px;
  padding: 8px;
  font-size: 1em;
`;

const SendMessageButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px;
  font-size: 1em;
  border: none;
  cursor: pointer;
`;

const BuyMeCoffeeLink = styled.a`
  display: block;
  margin-top: 20px;
  text-align: center;
  font-size: 1.2em;
  color: #4caf50;
`;

const ConnectAndSupport = () => {
  const auth = useContext(AuthContext);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [serverMessage, setServerMessage] = useState('')
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoding] = useState(false);

  const handleSendMessage = async (e) => {
    e.preventDefault();
  
    const apiEndpoint = `${process.env.REACT_APP_API_URL}/contact`;
  
    // Prepare the request body
    const requestBody = auth.isLoggedIn
      ? {
          name: auth.userName,
          email: auth.email,
          message,
        }
      : {
          name,
          email,
          message,
        };
  
    try {
      setIsLoding(true);
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
      const responseData = await response.json();
  
      // Check if the request was successful
      if (!response.ok) {
        setIsLoding(false);
        console.error('Error sending message:', responseData.message);
        setServerMessage(responseData.message)
        return;
      }
      setIsLoding(false);
      setServerMessage(responseData.message)
  
    } catch (error) {
      setIsLoding(false);
      console.error('Error sending message:', error);
      setServerMessage('Something went wrong! Please try again!')
    }
  };
  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      setEmailError('Email cannot be empty');
      return false;
    }

    if (!emailRegex.test(email)) {
      setEmailError('Invalid email format');
      return false;
    }

    setEmailError('');
    return true;
  };

  return (
    <>
    <Helmet>
    <title>Connect and Support | Setu Shubham</title>
    <meta name="description" content="If you have any feedback or just want to say hi, drop me a message." />
    </Helmet>
    <ConnectAndSupportWrapper>
      {isLoading && <LoadingSpinner asOverlay />}
      <GreetingMessage>
        Thanks for connecting! If you have any feedback or just want to say hi, drop me a message.
      </GreetingMessage>
      {auth.isLoggedIn ? (
        <SupportForm onSubmit={handleSendMessage}>
          <FormTextarea
            placeholder="Your message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
          <SendMessageButton type="submit">Send Message</SendMessageButton>
          {serverMessage && <div>{serverMessage}</div>}
        </SupportForm>
      ) : (
        <SupportForm onSubmit={handleSendMessage}>
          <FormInput
            type="text"
            placeholder="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <FormInput
            type="email"
            placeholder="Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={validateEmail}
            required
          />
           {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
          <FormTextarea
            placeholder="Your message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
          <SendMessageButton type="submit">Send Message</SendMessageButton>
          {serverMessage && <div>{serverMessage}</div>}
        </SupportForm>
      )}
      <BuyMeCoffeeLink href="https://www.buymeacoffee.com/setushubham" target="_blank">
        Feeling extra generous? Buy me a book! 📖
      </BuyMeCoffeeLink>
    </ConnectAndSupportWrapper>
    </>
  );
};

export default ConnectAndSupport;
